import api from './api'
export default {
    getAll(){
        return api.execute(`GET`,`/acontact`)
    },
    sendTestMessage(data) {
        return api.execute(`GET`,`/acontact/test?acid=${data.acid}`)
    },
    deleteAlerts(data){
        return api.execute(`DELETE`,`/acontact`,data)
    },
    createAlert(data){
        return api.execute(`POST`,`/acontact`,data)
    },
    editAlert(data){
        return api.execute(`PUT`,`/acontact`,data)
    },
    sendOtp(data){
        return api.execute(`post`,`/acontact/genotp`,data)
    },
    verifyOtp(data){
        return api.execute(`post`,`/acontact/verify`,data)
    }
}
<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <px-card>
                        <b-row>
                            <b-col>
                                <h6 class="text-primary">Alert Contact Information</h6>
                            </b-col>
                        </b-row><hr>
                        <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Alert Contact Type *
                            </b-col>
                            <b-col md="4">
                                <div class="form-group">
                                <b-form-select name="type" size="sm" v-model="input.type" :options="alert_opt" :state="validate_type" 
                                :disabled="edit_alert" required></b-form-select>
                                <b-form-invalid-feedback :state="validate_type">Select the alert contact type</b-form-invalid-feedback>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <div v-show="input.type != null">
                            <b-row class="mb-2">
                                <b-col md="3" class="font-weight-bold">
                                    Friendly Name
                                </b-col>
                                <b-col md="4">
                                    <b-form-input size="sm" type="text" name="name" v-model="input.name" placeholder="name" maxlength="50" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                        <div v-if="input.type == 5">
                            <b-row v-if="input.type == 5" class="">
                                <b-col md="3" class="font-weight-bold">
                                    Secret Key
                                </b-col>
                                <b-col md="6">
                                    <b-form-input size="sm" type="text" v-model="input.sk_webhook" maxlength="80" placeholder="" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                        </div>
                            <b-row v-if="input.type != 4" class="">
                                <b-col md="3" class="font-weight-bold">
                                    <span v-if="input.type">{{ related_id_lable[input.type].text }}</span>
                                </b-col>
                                <b-col md="6">
                                    <b-form-input size="sm" type="text" v-model="input.alertToUrl" placeholder="" maxlength="150" required></b-form-input>
                                    <b-form-invalid-feedback :state="validate_alertToUrl" v-if="input.type">Please enter {{ related_id_lable[input.type].text }} of selected type</b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 1">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ul>
                                        <li>Please enter Email Id above.</li>
                                    </ul>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 2">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ul>
                                        <li>Please enter Skype Id above.</li>
                                        <li>Example: <span class="text-info">live:{skypeid}</span></li>
                                    </ul>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 3">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li>Please get telegram username from telegram profile.</li>
                                        <li>Your telegram username will look like <span class="text-info">@demousername</span>, but enter it without the <span class="text-danger">@</span>.</li>
                                        <li>Validation - You can use a-z, 0-9 and underscores. Minimum length is 5 characters and maximum length 32 characters.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 4">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li>Please enter alert name only.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 5">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Webhook url in {{ related_id_lable[input.type].text }}.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 6 || input.type == 7">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Mobile number with country code in {{ related_id_lable[input.type].text }}.</li>
                                        <li>for example : <span class="text-info">+19999999999</span></li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 8">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Slack Email in {{ related_id_lable[input.type].text }}.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 9">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Zappier webhook url in {{ related_id_lable[input.type].text }}.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row class="mt-3 text-center">
                                <b-col>
                                    <b-button type="submit" variant="primary" :disabled="disable_submit">{{edit_alert?'Edit Alert':'Add New Alert'}}</b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </px-card>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import AlertController from "../../services/alertController";
export default{
    name : "AddAlerts",
    props:{add_alert_modal:Boolean,edit_alert:Boolean,alert_data:Object},
    data(){
        return {
            disable_submit:false,
            input:{type:null,name:'',alertToUrl:''},
            alert_opt:[
              { value: null, text:'Please Select' },
              { value: 1, text:'Email', disabled:true },
              { value: 2, text:'Skype', disabled:true  },
              { value: 3, text:'Telegram', disabled:true  },
              { value: 4, text:'Web push', disabled:true  },
              { value: 5, text:'Webhook', disabled:true  },
              { value: 6, text:'Message', disabled:true  },
              { value: 7, text:'Call', disabled:true  },
              { value: 8, text:'Slack', disabled:true  },
              { value: 9, text:'Zappier webhook', disabled:true  }
            ],
            related_id_lable:[
              { value: null, text:'Please Select' },
              { value: '1', text:'Email Id' },
              { value: '2', text:'Skype Id' },
              { value: '3', text:'Telegram Id' },
              { value: '4', text:'Web Push Url' },
              { value: '5', text:'Webhook Url' },
              { value: '6', text:'Contact Number' },
              { value: '7', text:'Contact Number' },
              { value: '8', text:'Slack User email' },
              { value: '9', text:'Zappier Webhook Url' }
            ],
            plan_details:[],
            payload:{}
        }
    },
    computed:{
        validate_type(){
            if(this.input.type == null){return false;}
            else{return true;}
        },
        validate_alertToUrl(){
            if(this.input.alertToUrl == null){return false;}
            else{return true;}
        }
    },
    created(){
        this.plan_details = this.$root.$data.plan_details
        for(var i=0; i< this.alert_opt.length; i++ ){
            this.plan_details.alertsTypes.map((mt) => { 
                if(this.alert_opt[i].value == mt){
                    this.alert_opt[i].disabled = false
                }
             })
        }
    },
    mounted(){
        this.$root.$data.alert_id = ''
        if(this.edit_alert){
             this.input = this.alert_data
        }
    },
    methods:{
       async onSubmit(){
        this.disable_submit = true
        this.payload = {"name": this.input.name,"type": this.input.type,"alertToUrl": this.input.alertToUrl}
        switch(this.input.type){
            case 1: 
                if(this.validateEmail(this.input.alertToUrl)){
                    this.payload = {"name": this.input.name,"type": this.input.type,"alertToUrl": this.input.alertToUrl}
                }
                else{
                    this.payload = {}
                    this.$toasted.show("Invalid Email", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
                break;
            case 3: 
                    let validateTelegram = this.isValidTelegramId(this.input.alertToUrl)
                    if(!validateTelegram.valid){
                    this.$toasted.show(validateTelegram.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                    this.disable_submit = false
                        return;
                    }
                break;
            case 4: this.payload = {"name": this.input.name,"type": this.input.type}
                break;
            case 5: this.payload.sk_webhook = this.input.sk_webhook
                break;
            case 8: 
                if(!this.validateEmail(this.input.alertToUrl)){
                    this.$toasted.show("Invalid Email", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                    this.disable_submit = false
                    return;
                }
                break;
        }
        if(Object.keys(this.payload).length !== 0){
                var response
                if(this.edit_alert){
                     var p = { "acid": this.input._id, "changePayload":this.payload }
                     response= await AlertController.editAlert(p)
                }
                else{
                    response= await AlertController.createAlert(this.payload)
                    if(response.data && response.data.aid){
                      this.$root.$data.alert_id = response.data.aid}
                }
                if(response.result){
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                    this.$emit('alertmodal')
                }
                else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
            }
            this.disable_submit = false
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
          },
        isValidTelegramId(input) {
            const regexPattern = /^[a-zA-Z0-9_]{5,32}$/;
            if (!input) {
                return { valid: false, message: "Telegram ID is required." };
            }
            if (input.length < 5 || input.length > 32) {
                return { valid: false, message: "Telegram ID must be between 5 and 32 characters." };
            }
            if (!regexPattern.test(input)) {
                return { valid: false, message: "Invalid Telegram ID. It can only contain letters, digits, and underscores." };
            }
             return { valid: true, message: "Valid Telegram ID." };
        }
    }
}
</script>